<template>
    <div>
        <h2>Historique des communications </h2>
        <hr/>
        <br/>
        Prochainement ...
    </div>
</template>



<style lang="scss">
</style>

<script>

import autoRefresh from '@/assets/utils/autoRefresh'

export default {
    data(){
        return{

        }
	},
	mounted:function()
	{
        //autoRefresh
		autoRefresh.SetCallback(()=>{
			//refresh todo
        })
        autoRefresh.refresh()
    },
    watch:{
        $route (){
            
        }
    },
    methods:{
		
    }
};

</script>